import '../css/App.css';
import { Routes, Route } from "react-router-dom";

import ViewGuestList from './ViewGuestList';
import Home from './Home'

function App() {
  // const [user, setUser] = useState([])
  // const [submitted, setSubmitted] = useState(false)

  // useEffect(() => {
  //   fetch("https://sids-rsvp.onrender.com/api/check_session").then((r) => {
  //     if (r.ok) {
  //       console.log(":)")
  //       r.json().then((user) => setUser(user));
  //     }
  //   });
  // }, [submitted]);
  return (
    <div className="App">
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/guests' element={<ViewGuestList />} />
      </Routes>
    </div>
  );
}

export default App;
