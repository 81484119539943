import React, { useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { useFormik } from "formik";
import * as yup from "yup";
import '../css/RSVP.css'

const RSVPForm = () => {
  //const [submittedGuests, setSubmittedGuests] = useState([])
  //const [guests, setGuests] = useState([{}]);
  //const [refreshPage, setRefreshPage] = useState(false);
  const [selected, setSelected] = useState('Phone#')

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const formSchema = yup.object().shape({
    name: yup.string().required("Must enter a name"),
    numberAttending: yup.number().positive().integer().typeError("Please enter a positive number"),
    email: yup.string().when('selected', {
      is: 'Email',
      then: yup.string().email("Invalid email").required("Must enter Email"),
    }),
    phoneNumber: yup.string().when('selected', {
      is: 'Phone#',
      then: yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Must enter Phone#"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      numberAttending: 0
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      fetch("https://sids-rsvp.onrender.com/api/guest", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values, null, 2),
      }).then((res) => {
        if (res.status === 201) {
          res.json().then(r => {
            // setUser(r)
            // setSubmitted((current) => !current)
            window.alert("Successfully submitted")
          })

        }
      });
    },
  });

  // const handleChange = (index, e) => {
  //   const { name, value } = e.target;
  //   const updatedGuests = [...guests];
  //   updatedGuests[index] = { ...updatedGuests[index], [name]: value };
  //   setGuests(updatedGuests);
  // };

  // const handleAddGuest = () => {
  //   setGuests([...guests, { name: '', contact: '' }]);
  // };

  // const handleRemoveGuest = (index) => {
  //   const isConfirmed = window.confirm('Are you sure you want to remove this guest?');
  //   if (isConfirmed) {
  //     const filteredGuests = guests.filter((_, i) => i !== index);
  //     setGuests(filteredGuests);
  //   }

  // }

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const isConfirmed = window.confirm('Are you sure you want to submit?');
  //   if (isConfirmed) {
  //     setSubmittedGuests(guests)
  //   }
  // };

  // if (user.length !== 0) {
  //   return (
  //     <div>
  //       <button onClick={() => setUser([])}>UN USER</button>
  //       <ul>
  //         <li>{user.name}</li>
  //         <li>{user.number_attending}</li>
  //       </ul>
  //     </div>
  //   )
  // }

  // if (user.name) {
  //   return (
  //     <div>
  //       <li>{user.name}</li>
  //       <li>{user.id}</li>
  //     </div>
  //   )
  // }

  return (
    <form onSubmit={formik.handleSubmit} className='RSVP-Form'>
      <h3 className='rsvp-h3'>RSVP</h3>
      <div className='RSVP-Form-card'>
        <label>Name:</label>
        <input
          type="text"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          required
        />
        <p style={{ color: "red" }}> {formik.errors.name}</p>

        <label>How many will be attending?</label>
        <input
          type="number"
          name="numberAttending"
          value={formik.values.numberAttending}
          onChange={formik.handleChange}
          required
        />
        <p style={{ color: "red" }}> {formik.errors.numberAttending}</p>

        <label htmlFor='contact-info-select'>Please provide your Phone# OR Email:</label>
        <select id='contact-info-select' onChange={(e) => setSelected(e.target.value)}>
          <option value='Phone#'>Phone#</option>
          <option value='Email'>Email</option>
        </select>
        {selected === "Phone#" ?
          <div>
            <label>Phone#:</label>
            <PatternFormat
              name='phoneNumber'
              format="+1 (###) ### ####"
              allowEmptyFormatting mask="_"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              required
            />
            <p style={{ color: "red" }}> {formik.errors.phoneNumber}</p>
          </div> :
          <div>
            <label>Email:</label>
            <input
              type='email'
              name='email'
              placeholder="example@example.com"
              value={formik.values.email}
              onChange={formik.handleChange}
              required
            />
            <p style={{ color: "red" }}> {formik.errors.email}</p>

          </div>
        }
        {/* <button type='button' className='remove-guest' onClick={(e) => handleRemoveGuest(index)}>Remove Guest</button> */}
      </div>
      {/* <button type="button" className='add-guest' onClick={handleAddGuest}>Add Guest</button> */}
      <button type="submit" className='submit-button'>Submit</button>
      <br />
      <p className='scroll-for-more'><em>Scroll For More</em></p>

    </form>
  );
}

export default RSVPForm;
