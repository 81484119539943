import { useState, useEffect } from "react"

function ViewGuestList() {
    const [guests, setGuests] = useState([])
    const [totalGuests, setTotalGuests] = useState(0);

    useEffect(() => {
        fetch("https://sids-rsvp.onrender.com/api/guest")
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Network response was not ok.');
            })
            .then((guestData) => {
                setGuests(guestData);
            })
            .catch(error => console.error('There has been a problem with your fetch operation:', error));
    }, []);

    useEffect(() => {
        const excludedIds = [2, 3, 60];
        const total = guests.reduce((acc, guest) => {
            if (!excludedIds.includes(guest.id)) {
                return acc + guest.number_attending;
            }
            return acc;
        }, 0);
        setTotalGuests(total);
    }, [guests]);

    return (

        <div>
            <h1 style={{ color: 'white' }}>Total Guests: {totalGuests}</h1>
            {guests.map(guest => {
                return (
                    <div key={guest.id}>
                        <p style={{ color: 'white' }}>
                            Name: {guest.name}
                            &nbsp;||&nbsp;Number Attending: {guest.number_attending}
                            &nbsp;||&nbsp;{guest.phone_number.length > 0 ? `Phone Number: ${guest.phone_number}` : `Email: ${guest.email}`}
                            &nbsp;||&nbsp;Date Created: {guest.date_added}
                        </p>
                    </div>
                )
            })}
        </div >
    )
}

export default ViewGuestList