import RSVPForm from './RSVPForm';
import sidsVenmo from '../assets/sids-venmo.jpg'
import sidsPaypal from '../assets/sids-paypal.jpg'

function Home() {

    return (
        <div>
            <div className='wrapper'>
                <h1 className='header'>Sidney & Guy's<br />Pre-Wedding Reception</h1>
                <div className='directions-wrapper'>
                    <p className='invite'>
                        We are having an intimate destination wedding in
                        July 2024. We would love to celebrate with our friends
                        and family, and so, we invite you to do that with us at our
                        Pre-Wedding Reception.
                    </p>
                    <p>
                        The reception will be held on: June 8th, 1pm-6pm, Open House style <br></br>

                        The reception will be located at: <a className='directions-a-tag'
                            href='https://maps.app.goo.gl/fP8LVHMBxYwhZC1f7' target='_blank'
                            rel="noopener noreferrer"><br></br>1760 Summit Drive 80921</a>
                    </p>

                </div>
                <RSVPForm />
                <p className='scroll-for-more'><em>Scroll For More</em></p>
                <div className='donate-wrapper'>
                    <p>
                        As you know, we've already made a comfortable home together.
                        In lieu of gifts, please consider making a donation to our
                        Switzerland Honeymoon that is booked in August!
                    </p>
                    <div className='donate-options-wrapper'>
                        <a href='https://venmo.com/code?user_id=2799605016166400516&created=1715556638' target='_blank' rel="noreferrer">
                            <br />Donate Venmo Here<br />
                            <img src={sidsVenmo} alt='sids-venmo' className='donate-image' />
                        </a>
                        <a href='https://paypal.me/scr00k?country.x=US&locale.x=en_US' target='_blank' rel="noreferrer">
                            <br />Or PayPal Here<br />
                            <img src={sidsPaypal} alt='sids-paypal' className='donate-image' />
                        </a>
                    </div>


                </div>
                <div className='grid-container'>
                    <div className='grid-item one'></div>
                    <div className='grid-item two'></div>
                    <div className='grid-item three'></div>
                    <div className='grid-item four'></div>
                    <div className='grid-item five'></div>
                    <div className='grid-item six'></div>
                </div>
            </div>
        </div>
    )
}

export default Home